import React, { FC, memo } from 'react'
import { Container } from '~ux'
import { FORM_ID } from '~consts'
import ScrollButton from './ScrollButton'

interface HiringProps {}

const Hiring: FC<HiringProps> = () => {
  return (
    <>
      <div className="bg-[#F9F9F8]">
        <Container>
          <div className="flex justify-between py-100 xs:py-80 md:block">
            <div>
              <ScrollButton className="font-semibold" variant="primary" size="lg" scrollTo={FORM_ID}>
                Технология - Супер Гео таргетинг
              </ScrollButton>
              <div className="max-w-[51.875rem] pt-32 text-32 leading-40 font-bold text-purple">
                Повысьте скорость найма
                <span className="text-[#FF7143]"> в маленьких городах/поселках</span>
              </div>

              <div className="hidden center max-w-fit md:block md:py-30">
                <img className="" src="/images/statisticYear.png" alt="statisticYear" />
              </div>
              <div className="text-16 leading-24 max-w-[42.125rem] font-regular text-purple/60 mt-16">
                Находим кандидатов в любых городах даже с минимальной численностью за счет собственной технологии
                настройки таргетинга. Протестировано в городах, где проживают менее 1000 человек
              </div>
            </div>
            <div className="md:hidden">
              <img src="/images/statisticYear.png" alt="statisticYear" />
            </div>
          </div>
        </Container>
      </div>
    </>
  )
}

export default memo(Hiring)
