import React, { FC, memo } from 'react'
import { FORM_ID } from '~consts'
import { ScrollButton } from '~ui'
import { Container } from '~ux'

interface TargetProps {}

const Target: FC<TargetProps> = () => {
  return (
    <>
      <div className="bg-orange">
        <Container className="">
          <div className="flex justify-between md:block">
            <img
              className="max-w-fit md:hidden object-contain object-bottom"
              src="images/superGeo.png"
              alt="superGeo2"
            />

            <div className="">
              <div className="pt-70 text-white max-w-[49rem] text-32 leading-40 font-bold md:pt-50 md:text-center md:max-w-100% md:mb-32">
                Каков он СуперГео таргетинг в действии?
              </div>

              <div className="pt-20 mb-70 md:pt-0 md:pb-50 md:flex md:justify-center">
                <ScrollButton variant="white" scrollTo={FORM_ID}>
                  Попробовать!
                </ScrollButton>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  )
}

export default memo(Target)
