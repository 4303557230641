import React, { FC, memo } from 'react'
import { Container } from '~ux'

interface IntegrationProps {}

const Integration: FC<IntegrationProps> = () => {
  return (
    <div className="bg-ats bg-cover">
      <Container className="py-80 xs:py-40">
        <div className="text-32 leading-40 font-bold text-purple max-w-[50rem]">
          Реклама эффективнее <span className="text-[#FF7143]">в связке с ATS</span>
        </div>
        <div className="grid grid-cols-2 gap-x-30 flex md:block">
          <div className=" text-[#373773]">
            <div className="max-w-[26.25rem] text-16 leading-24 font-regular text-purple/60 mt-16">
              Используйте встроенную ATS или мы подключимся к вашей
            </div>
            <div className="text-center">
              <div className="grid grid-cols-2 gap-x-30 mt-32">
                <div className="max-w-[22.5rem] min-h-[9.438rem] py-10 px-24 bg-white rounded-20 flex justify-center items-center text-16 xs:text-12">
                  {' '}
                  Обновляем потребность каждый день
                </div>
                <div className="max-w-[22.5rem] min-h-[9.438rem] py-12 px-26 bg-white rounded-20 flex justify-center items-center text-16 xs:text-12">
                  Оптимизируем рекламу на основе данных о прохождении кандидатом по воронке
                </div>
              </div>
              <div className="grid grid-cols-2 gap-x-30 mt-40 ">
                <div className="max-w-[22.5rem] min-h-[9.438rem] py-12 px-26 bg-white rounded-20 flex justify-center items-center text-16 xs:text-12">
                  {' '}
                  Никаких Excel и Google sheets (опционально)
                </div>
                <div className="max-w-[22.5rem] min-h-[9.438rem] py-12 px-26 bg-white rounded-20 flex justify-center items-center text-16 xs:text-12">
                  День в день запуск по новым регионам, вакансиям
                </div>
              </div>
              <div className="grid grid-cols-2 gap-x-30 mt-40 ">
                <div className="max-w-[22.5rem] min-h-[9.438rem] py-12 px-26 bg-white rounded-20 flex justify-center items-center text-16 xs:text-12">
                  Стабильный фокус на самых горящих позициях
                </div>
              </div>
            </div>
          </div>

          <div className="pl-30 md:pl-0">
            <div className="max-w-[26.25rem] text-16 leading-24 font-regular text-purple/60 mt-16">
              Интеграция с другими ATS
            </div>
            <img className="mt-32 xs:mt-14" src="/images/atsLogos-new.png" alt="atsLogos" />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default memo(Integration)
