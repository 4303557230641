import React, { FC, memo } from 'react'
import { Container } from '~ux'

interface VacanciesProps {}

const tasks: string[] = ['Ритейл', 'Контакт-центр', 'Логистика', 'Банки', 'IT']

const Vacancies: FC<VacanciesProps> = ({}) => {
  return (
    <div className="bg-[#F9F9F8] sm:pb-40">
      <Container className="pt-64 xs:pt-40">
        <div className="flex md:block md:relative">
          <div className="">
            <h2 className="text-32 leading-40 font-bold text-purple mt-32 md:mt-0">
              Закроем <div className="inline text-[#FF7143]">любые вакансии</div>
            </h2>

            <div className="text-16 leading-24 font-regular text-purple/60 mt-16">
              Благодаря автоматизиции маркетинга и опытной команде мы в кратчайшие сроки достигаем поставленных задач и
              помогаем закрыть самые горящие позиции
            </div>

            <div className="text-16 leading-24 text-purple/60 font-regular mt-32">Особенно хорошо получается:</div>

            <div className="flex flex-wrap gap-16 mt-32 pb-64">
              {tasks.map((text, index) => (
                <div key={index} className="bg-[#FF7143] rounded-full py-12 px-32">
                  <span className="font-regular text-16 leading-24 text-white">{text}</span>
                </div>
              ))}
            </div>
          </div>
          <img className="w-2/5 md:hidden sm:hidden" src={`/images/vacancyWoman.png`} alt="" />
        </div>
      </Container>
    </div>
  )
}

export default memo(Vacancies)
