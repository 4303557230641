import React, { FC, memo } from 'react'
import { Container } from '~ux'

interface OurTeamProps {}

const OurTeam: FC<OurTeamProps> = () => {
  return (
    <>
      <Container className="flex items-center md:mt-23 md:block">
        <div>
          <div className="max-w-[52.438rem] text-32 leading-40 font-bold text-purple">
            Наша команда - <span className="text-[#28D2AE]">это Digital маркетологи мирового уровня</span>
          </div>
          <div className="max-w-[42.125rem] text-16 leading-24 font-regular text-purple/60 mt-16 md:mb-64">
            Работая с нашими экспертами по контекстной и таргетированной рекламе вы получаете набор профессиональных
            навыков в совокупности с опытом ведения рекламных кампаний на миллионы рублей в HR лидогенерации
          </div>
        </div>

        <div className="max-w-[42.125rem] md:hidden">
          <img src="images/ourTeam.png" alt="ourTeam" />
        </div>
      </Container>
    </>
  )
}

export default memo(OurTeam)
