import React, { FC, memo } from 'react'
import { Container } from '~ux'

interface BenefitsProps {}

const Benefits: FC<BenefitsProps> = () => {
  return (
    <>
      <Container>
        <div className="flex justify-around items-center my-80 xs:my-60 xs:flex-col sm:my-60 sm:flex-col">
          <div className="">
            <div className="text-20 leading-30 text-purple font-semibold max-w-[39.938rem]">
              Получите <span className="text-[#28D2AE]">максимальное количество заявок</span> с помощью конверсионных
              лендингов
            </div>
            <div className="max-w-fit hidden sm:block sm:py-60 xs:py-30">
              <img src="images/perekrestok_2.png" alt="perekrestok_1" />
            </div>
            <div className="text-16 text-purple/60 leading-24 mt-16 xs:text-14 xs:leading-22 max-w-[32.063rem] pt-16">
              Наши сайты дают высокую конверсию из входящего трафика в заявки от кандидатов за счет протестированных и
              оптимизированных на большом бюджете блоков, встроенного конструктора сайтов
            </div>
          </div>
          <div className="max-w-[32rem] md:max-w-[27rem] max-h-[32.25rem] xs:hidden sm:hidden pl-20">
            <img src="images/perekrestok_2.png" alt="perekrestok_1" />
          </div>
        </div>

        <div className="flex items-center justify-between xs:flex-col sm:my-60 sm:flex-col">
          <div className="max-w-[32rem] md:max-w-[27rem] max-h-[32.25rem] xs:hidden sm:hidden pr-20">
            <img src="images/perekrestok_1.png" alt="perekrestok_1" />
          </div>
          <div>
            <div className="text-20 leading-30 text-purple font-semibold max-w-[39.938rem]">
              <span className="text-[#FF7143]">Найдем всех,</span> кто ищет работу или только присматривается
            </div>
            <div className="max-w-fit hidden sm:block md:py-60 xs:py-30">
              <img src="images/perekrestok_1.png" alt="perekrestok_1" />
            </div>
            <div className="text-16 text-purple/60 leading-24 mt-16 xs:text-14 xs:leading-22 max-w-[32.063rem] pt-16">
              Запуск рекламных компаний в поисковиках и социальных сетях позволяет охватить кандидатов на самых разных
              площадках и значительно увеличить поток за счет кандидатов, недоступных на карьерных порталах
            </div>
          </div>
        </div>

        <div className="flex justify-around items-center my-80 xs:my-60 xs:flex-col sm:my-60 sm:flex-col">
          <div>
            <div className="text-20 leading-30 text-purple font-semibold max-w-[39.938rem]">
              Легко принимайте правильные решения <span className="text-[#28D2AE]">на основе прозрачных отчетов</span>
            </div>
            <div className="max-w-fit hidden sm:block md:py-60 xs:py-30">
              <img src="images/perekrestok_3.png" alt="perekrestok_3" />
            </div>
            <div className="text-16 text-purple/60 leading-24 mt-16 xs:text-14 xs:leading-22 max-w-[32.063rem] pt-16">
              Расчет стоимости привлечения с разбивкой по источникам, по этапам воронки. Возможность прогнозировать
              стоимость в зависимости от региона, позиции, фактических конверсий и необходимого количества
              трудоустроенных
            </div>
          </div>
          <div className="max-w-[32rem] md:max-w-[27rem] max-h-[32.25rem] xs:hidden sm:hidden pl-20">
            <img src="images/perekrestok_3.png" alt="perekrestok_3" />
          </div>
        </div>
      </Container>
    </>
  )
}

export default memo(Benefits)
