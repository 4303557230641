import React, { FC, memo } from 'react'
import { Container } from '~ux'

interface AdvertisementProps {}

const images: string[] = [
  '/images/yandex.png',
  '/images/pc.png',
  '/images/vk.png',
  '/images/ads.svg',
  '/images/target.png',
  '/images/avito.png',
]

const Advertisement: FC<AdvertisementProps> = ({}) => {
  return (
    <Container className="mt-100">
      <h2 className="max-w-[60%] text-32 leading-40 text-purple font-black md:max-w-full">
        Реклама ваших вакансий на всех площадках{' '}
        <div className="inline text-[#FF7143]">где есть подходящие кандидаты</div>
      </h2>
      <div className="flex flex-wrap items-center justify-between mt-60 md:gap-x-20 sm:gap-x-0 xs:gap-x-20 md:justify-center sm:justify-between xs:justify-center">
        {images.map((src, index) => (
          <img className="w-[10%] md:w-[20%] sm:w-[28%] xs:w-[40%]" key={index} src={src} alt="" />
        ))}
      </div>
    </Container>
  )
}

export default memo(Advertisement)
