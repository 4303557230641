import React, { FC, memo } from 'react'
import { Container } from '~ux'

interface GoodCompanyProps {}

interface ICards {
  img: string
  title: string
  description: string
}

const cards: ICards[] = [
  {
    img: '/images/goodCompanyItem_1.png',
    title: 'Ритейл',
    description: 'Продавец-кассир, старший кассир, кладовщик и т.д.'
  },
  {
    img: '/images/goodCompanyItem_3.png',
    title: 'Банки',
    description: 'Специалист контактного центра, клиентский менеджер, менеджер прямых продаж'
  },
  {
    img: '/images/goodCompanyItem_2.png',
    title: 'Производство',
    description: 'Оператор производственной линии, оператор линии сборки'
  }
]

const GoodCompany: FC<GoodCompanyProps> = () => {
  return (
    <>
      <div className="bg-goodCompany bg-cover bg-no-repeat">
        <Container>
          <div className="py-64 xs:py-23">
            <div className="text-white text-32 leading-40 font-bold">Вы в хорошей компании</div>
            <div className="flex flex-nowrap gap-x-50 mt-60 md:overflow-x-scroll md:pb-30 xs:gap-x-20 xs:mt-35">
              {cards.map(({ title, description, img }, index) => (
                <div
                  key={index}
                  className="w-[30%] rounded-42 shadow-CARD px-32 py-42 bg-white md:min-w-[26.875rem] xs:min-w-[14rem] xs:py-15 xs:rounded-22"
                >
                  <img className="h-[13rem] xs:h-[9rem] object-contain" src={img} alt="" />
                  <div className="mt-32 xs:mt-24">
                    <h4 className="text-20 leading-38 text-purple font-semibold">{title}</h4>
                    <div className="text-16 text-purple/60 leading-24 mt-16 xs:text-14 xs:leading-22">
                      {description}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Container>
      </div>
    </>
  )
}

export default memo(GoodCompany)
