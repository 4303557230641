import React, { FC, memo } from 'react'
import { Container } from '~ux'

interface ToolsProps {}

interface ICards {
  img: string
  title: string
  description: string
}

const cards: ICards[] = [
  {
    img: '/images/hr.png',
    title: 'HR-Порталы',
    description: 'Эффективные динамические посадочные страницы, А/В тесты, сбор данных по источникам'
  },
  {
    img: '/images/advertisement.jpg',
    title: 'Реклама',
    description: 'Сотни управляемых автоматически кампаний, оптимизация, контроль бюджета'
  },
  {
    img: '/images/management.png',
    title: 'Управление',
    description: 'Воронка статусов, оценка эффективности, аналитика по каналам'
  }
]

const Tools: FC<ToolsProps> = ({}) => {
  return (
    <Container className="mt-90 pb-150 xs:pb-50">
      <h2 className="text-32 leading-40 font-black text-purple">Наши инструменты</h2>
      <div className="flex flex-nowrap gap-x-50 mt-60 md:overflow-x-scroll md:pb-30 xs:gap-x-20 xs:mt-35">
        {cards.map(({ title, description, img }, index) => (
          <div
            key={index}
            className="w-[30%] rounded-42 shadow-CARD px-32 py-42 bg-white md:min-w-[26.875rem] xs:min-w-[14rem] xs:py-15 xs:rounded-22"
          >
            <img className="h-[13rem] xs:h-[9rem] object-contain" src={img} alt={description} />
            <div className="mt-32 xs:mt-24">
              <h4 className="text-20 leading-38 text-purple font-semibold">{title}</h4>
              <div className="text-16 text-purple/60 leading-24 mt-16 xs:text-14 xs:leading-22">{description}</div>
            </div>
          </div>
        ))}
      </div>
    </Container>
  )
}

export default memo(Tools)
