import React, { FC, memo } from 'react'
import { FORM_ID } from '~consts'
import { ScrollButton } from '~ui'
import { Container } from '~ux'
import * as Icon from '~svg'

interface MarketingPlatformProps {}

const MarketingPlatform: FC<MarketingPlatformProps> = () => {
  const handleButtonClick = () => {
    window.scrollBy(0, 1000000)
  }
  return (
    <Container>
      <div className="grid grid-cols-2 pt-90 md:block">
        <div className="s">
          <div className="text-22 leading-41 text-purple/60 font-medium mb-10">HR маркетинг платформа</div>
          <h1 className="inline text-42 text-[#373773] font-black leading-50">
            Поток кандидатов
            <br />
            <div className="inline text-[#FF7143]">на любые вакансии</div>
          </h1>

          <div className="block mt-32">
            <div className="text-purple/60 text-16 leading-24">
              Запуск новой вакансии или города за 1 день
              <br />
              Любые проблемные вакансии/города
              <br />
              Опытная команда маркетологов
            </div>

            <div className="min-w-fit flex items-center gap-x-25 mt-64 md:mt-50">
              <ScrollButton
                className="font-semibold"
                onClick={handleButtonClick}
                variant="primary"
                size="lg"
                scrollTo={FORM_ID}
              >
                Привлечь кандидатов
              </ScrollButton>
            </div>
          </div>
        </div>

        <img className="md:hidden rotate-[2.32deg]" src="/images/main.png" alt="" />
      </div>
    </Container>
  )
}

export default memo(MarketingPlatform)
